import gsap from 'gsap';

interface AnimatePopFromBottomTextProps {
  elementsToAnimate: HTMLElement[];
  triggerElement: HTMLElement | string;
  yTranslate: number;
  duration: number;
  animationStart?: string;
  delay?: number;
  onComplete?: gsap.Callback | undefined;
}

const animatePopFromBottomText = ({
  elementsToAnimate,
  triggerElement,
  yTranslate,
  duration,
  animationStart,
  delay,
  onComplete,
}: AnimatePopFromBottomTextProps) => {
  const tl = gsap.timeline({
    paused: true,
    scrollTrigger: {
      trigger: triggerElement,
      start: animationStart ?? 'top center',
    },
    delay,
    onComplete,
  });

  elementsToAnimate.forEach((item, index) => {
    tl.fromTo(
      item,
      {
        y: yTranslate,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        duration,
      },
      index === 0 ? '' : '=-0.65',
    );
  });
};

export { animatePopFromBottomText };
