import React, { createContext, useState } from 'react';

export const MouseContext = createContext({
  cursorType: '',
  // @ts-ignore
  cursorChangeHandler: (type?: string) => undefined,
});

interface MouseContextProps {
  children: JSX.Element[];
}
const MouseContextProvider = (props: MouseContextProps) => {
  const [cursorType, setCursorType] = useState('');

  const cursorChangeHandler = (type: string) => setCursorType(type);

  return (
    <MouseContext.Provider
      value={{
        cursorType,
        // @ts-ignore
        cursorChangeHandler,
      }}
    >
      {props.children}
    </MouseContext.Provider>
  );
};
export default MouseContextProvider;
