import React, { FC, InputHTMLAttributes, useState } from 'react';
import { FieldError } from 'react-hook-form/dist/types';

import './Input.scss';

export interface TextAreaInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  hasError: boolean | FieldError | undefined;
  label: string;
  inputValidation?: object;
}

const TextAreaInput: FC<TextAreaInputProps> = ({
  placeholder,
  name,
  className,
  hasError,
  label,
  onChange,
  ...rest
}) => {
  const [inputChanged] = useState(false);
  return (
    <div className="input__wrapper">
      <textarea
        id={`${name}-input`}
        placeholder={placeholder}
        className={`input__input input__input--text-area ${className} ${
          inputChanged ? '' : 'input__neutral-outline'
        } ${hasError ? 'input__invalid' : 'input__valid'}`}
        onChange={onChange}
        {...rest}
      />
      <label htmlFor={`${name}-input`} className="input__label">
        {label}
      </label>
      <br />
    </div>
  );
};

export default TextAreaInput;
