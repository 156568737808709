import React, { FC, useRef, useEffect, useCallback, useState } from 'react';

import { SplitText } from '@cyriacbr/react-split-text';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import useParseString from '@/hooks/useParseString';

import gsap from 'gsap';

import './AnimateText.scss';
import { animatePopFromBottomText } from '@/utils/animations/PopFromBottomText';

gsap.registerPlugin(ScrollTrigger);

type AnimationProps = {
  animationStart?: string;
  children?: JSX.Element | undefined | null | Text;
  text: string;
  trigger?: string;
};

const animatedWords: any[] = [];
let textWordList: string[] = [];

const AnimateText: FC<AnimationProps> = (props) => {
  const { trigger, text, animationStart } = props;
  const parser = useParseString();
  const [isBuilding, setIsBuilding] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isResize, setIsResize] = useState<boolean>(false);
  const transformedText = useRef(parser(text?.toString().replaceAll('<w>', '')));
  const animateDivRef = useRef(null);
  const highlightTl = useRef<GSAPTimeline | null>(
    gsap.timeline({
      scrollTrigger: {
        trigger: animateDivRef.current,
      },
      delay: 1.25,
    }),
  );

  const isTextHighlighted = text.includes('<w>');

  const animateWord = (lineIndex: number, wordIndex: number) => {
    const wordIdentifier = `phrase__${lineIndex}-${wordIndex}`;
    const wordWasAnimated = animatedWords.includes(wordIdentifier);
    if (wordWasAnimated) return;
    const word = new Array(document.querySelector(`.${wordIdentifier}`))[0];

    new Array(word?.children).map((letters) => {
      if (letters === undefined) return;
      const letterArray = [...letters];
      letterArray.forEach((letter: any, index) => {
        highlightTl.current.to(
          `.${letter.className}`,
          {
            color: 'white',
            duration: 0.15,
            delay: index === 0 ? Math.random() * 0.2 : 0,
          },
          '=-0.1',
        );
      });
    });

    animatedWords.push(wordIdentifier);
  };

  const onRefChange: React.MutableRefObject<unknown> | null | undefined = useCallback(
    (node: JSX.Element) => {
      if (node !== null) {
        setIsBuilding(false);
      }
    },
    [],
  );

  const resetHighlight = () => {
    if (highlightTl.current) {
      highlightTl.current.kill();
      highlightTl.current.clear();
      setIsResize(true);
    }
  };

  useEffect(() => {
    if (isTextHighlighted) window.addEventListener('resize', resetHighlight);
    setTimeout(() => setIsLoading(false), 100);

    return () => {
      if (isTextHighlighted) {
        window.removeEventListener('resize', resetHighlight);
      }
    };
  }, []);

  useEffect(() => {
    const el = animateDivRef.current;

    if (isBuilding || animateDivRef.current === null || animateDivRef === null || el === null)
      return;

    const animatedItems = Array.from(el.children[0].children) as HTMLElement[];

    animatePopFromBottomText({
      elementsToAnimate: animatedItems,
      triggerElement: trigger || animateDivRef.current,
      yTranslate: 65,
      duration: 0.75,
      animationStart,
    });
  }, [isBuilding]);

  const handleLineWrapper = (line: any) => {
    return (
      <div className="animate-text__line-wrapper">
        <div className={`animate-text__line-wrapper animate-text__line-${line.lineIndex}`}>
          {line.children}
        </div>
      </div>
    );
  };

  const handleWordWrapper = (word: any) => {
    let isWhite = false;

    if (isTextHighlighted) {
      if (textWordList.length === 0) {
        if (isResize) {
          resetHighlight();
        }
        textWordList = text?.split(' ');
      }
      const highlightedWord = textWordList[0];
      isWhite = highlightedWord?.includes('<w>');
      textWordList = textWordList.slice(1);
      if (isWhite) {
        setTimeout(() => animateWord(word.lineIndex, word.wordIndex), 50);
      }
    }

    return <span className={`phrase__${word.lineIndex}-${word.wordIndex}`}>{word.children}</span>;
  };

  const handleLetterWrapper = (letter: any) => {
    return (
      <span
        className={`letter--line-${letter.lineIndex}-word-${letter.wordIndex}-letter-${letter.letterIndex}`}
      >
        {letter.children}
      </span>
    );
  };

  const MemoizedSplitText = useCallback(() => {
    return (
      <SplitText
        ref={onRefChange}
        LineWrapper={handleLineWrapper}
        WordWrapper={handleWordWrapper}
        LetterWrapper={handleLetterWrapper}
      >
        {transformedText.current}
      </SplitText>
    );
  }, []);

  return (
    <div className="animate-text" ref={animateDivRef}>
      {!isLoading && <MemoizedSplitText />}
    </div>
  );
};

export default AnimateText;
