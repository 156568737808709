import React, { FC, InputHTMLAttributes, useState } from 'react';
import { FieldError } from 'react-hook-form/dist/types';

import './Input.scss';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError: boolean | FieldError | undefined;
  label: string;
  inputValidation?: object;
}

const Input: FC<InputProps> = ({
  type,
  placeholder,
  name,
  className,
  hasError,
  label,
  onChange,
  value,
  ...rest
}) => {
  const [inputChanged] = useState(false);
  return (
    <div className="input__wrapper">
      <input
        id={`${name}-input`}
        type={type}
        placeholder={placeholder}
        className={`input__input ${className} ${
          inputChanged ? '' : 'input__neutral-outline' // TODO: ADD INPUT CHANGED PROP
        } ${hasError ? 'input__invalid' : 'input__valid'}`}
        onWheel={(e) => {
          if (type === 'number') (e.target as HTMLElement).blur();
        }}
        onChange={onChange}
        value={value}
        {...rest}
      />

      <label htmlFor={`${name}-input`} className="input__label">
        {label}
      </label>
      <br />
    </div>
  );
};

export default Input;
