import * as yup from 'yup';

export const contactUsSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  detail: yup.string().required(),
});

export const defaultFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  detail: '',
};
