import React, { useEffect, useRef, useState, useContext } from 'react';
import { MouseContext } from '../../store/cursor';
import { CaseStudyData } from '../../mock/SiteData';
import RArr from '@/assets/icons/shape_arrow.svg';
import Slide from '@/components/shared/Slide/Slide';
import gsap from 'gsap';
import SlideTitles from '@/components/shared/SlideTitles/SlideTitles';
import Counter from '@/components/shared/counter/Counter';
import Cursor from '@/components/shared/cursors/Cursor';

import './ImgSliderDesk.scss';

const ImgSliderDesk = () => {
  const { cursorChangeHandler } = useContext(MouseContext);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isSlideChanging, setIsSlideChanging] = useState(false);
  const [isDescriptionAnimationRunning, setIsDescriptionAnimationRunning] = useState(false);
  const [previousArrowCursor, setPreviousArrowCursor] = useState<string>('default');
  const descriptionTl = useRef<GSAPTimeline | null>(null);

  const images = CaseStudyData.map((slide) => slide.sliderImage);
  const titles = CaseStudyData.map((slide) => {
    return { title: slide.shortTitle ? slide.shortTitle : slide.hero.title, link: slide.link };
  });

  const setNewArrowCursor = (type: string) => setPreviousArrowCursor(type);

  const changeSlideFoward = () => {
    if (currentIndex === images.length - 1) {
      onChangeSlide(0);
    } else {
      onChangeSlide(currentIndex + 1);
    }
  };

  const changeSlideBackWard = () => {
    if (currentIndex === 0) {
      onChangeSlide(images.length - 1);
    } else {
      onChangeSlide(currentIndex - 1);
    }
  };

  const onChangeSlide = (newIndex: number) => {
    if (isSlideChanging || isDescriptionAnimationRunning) return;
    setIsSlideChanging(true);
    setIsDescriptionAnimationRunning(true);
    setCurrentIndex(newIndex);

    if (descriptionTl.current === null) return;

    descriptionTl.current.to(
      `.image-slider-desk__description-text--${currentIndex}`,
      {
        opacity: 0,
        duration: 0.15,
      },
      '=0',
    );

    descriptionTl.current.to(
      `.image-slider-desk__description-text--${newIndex}`,
      {
        opacity: 1,
        duration: 0.25,
        delay: 0.3,
        onComplete: () => setIsDescriptionAnimationRunning(false),
      },
      '=0',
    );

    setTimeout(() => {
      setIsSlideChanging(false);
    }, 1000);
  };
  useEffect(() => {
    descriptionTl.current = gsap.timeline();
  }, []);

  return (
    <section className="image-slider-desk">
      <Cursor />
      <div className="image-slider-desk__wrapper">
        <div className="image-slider-desk__title-wrapper">
          <h3 className="image-slider-desk__title">\ Our Projects</h3>
          <div className="image-slider-desk__slide-number">
            /<br />
            <Counter
              currentIndex={currentIndex}
              maxCount={CaseStudyData.length}
              counterHeight={80}
              id="image-slider"
            />
          </div>
        </div>
        <div
          className="image-slider-desk__slider-wrapper"
          onMouseLeave={() => cursorChangeHandler('default')}
        >
          <Slide
            images={images}
            currentIndex={currentIndex}
            changeSlideBackWard={changeSlideBackWard}
            changeSlideFoward={changeSlideFoward}
            previousArrowCursor={previousArrowCursor}
            setPreviousArrowCursor={setNewArrowCursor}
          />
          <SlideTitles
            currentIndex={currentIndex}
            titles={titles}
            changeSlideBackward={changeSlideBackWard}
            changeSlideFoward={changeSlideFoward}
            previousArrowCursor={previousArrowCursor}
            setPreviousArrowCursor={setNewArrowCursor}
          />
          <div className="image-slider-desk__arrow-counter">
            <img
              src={RArr}
              alt="right-arrow"
              className="image-slider-desk__arrow-counter-arrow"
              onClick={changeSlideFoward}
              onMouseEnter={() => cursorChangeHandler('default')}
              onMouseLeave={() => cursorChangeHandler(previousArrowCursor)}
            />
            <div style={{ display: 'flex' }}>
              <Counter
                counterHeight={70}
                currentIndex={currentIndex}
                maxCount={CaseStudyData.length}
                className="image-slider-desk__arrow-counter-counter"
                id="image-slider"
              />
              <span className="image-slider-desk__arrow-counter--grey">
                &nbsp;
                {CaseStudyData.length >= 10 && `/${CaseStudyData.length}`}
                {CaseStudyData.length < 10 && `/0${CaseStudyData.length}`}
              </span>
            </div>
          </div>
        </div>
        <div
          className="image-slider-desk__description-wrapper"
          onMouseEnter={() => cursorChangeHandler('default')}
        >
          {CaseStudyData.map((slide, index) => (
            <p
              key={`id-description-${slide.id}`}
              className={`image-slider-desk__description-text image-slider-desk__description-text--${index} `}
              style={{ opacity: index === 0 ? 1 : 0 }}
            >
              {slide.description}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImgSliderDesk;
