import React, { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { navigate } from 'gatsby';
import { Mousewheel } from 'swiper';
import { Swiper as SwiperType } from 'swiper/types';
import RArr from '@/assets/icons/shape_arrow.svg';

import 'swiper/css';
import './Slider.scss';

export type SlideProps = {
  img: string;
  imgMobile: string;
  link: string;
  txt: string;
  title: JSX.Element | string;
  id: string;
};

type SliderProps = {
  slides: SlideProps[];
  isImageSlider?: boolean;
  onSlideChange: (index: number) => void;
};

const Slider: FC<SliderProps> = (props) => {
  const { slides, isImageSlider } = props;
  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
  const [, setSlideCount] = useState<number>();

  return (
    <div className="slider__wrapper">
      <div className="slider__section-wrapper">
        <div className="slider__counter-wrapper">
          <img
            src={RArr}
            alt="right-arrow"
            className=""
            onClick={() => swiperInstance?.slideNext()}
          />
          <div>
            0{(swiperInstance?.realIndex ?? 0) + 1}
            <span className="slider__counter--grey">/0{slides.length}</span>
          </div>
        </div>
        <div className="slider__slider-wrapper">
          {isImageSlider && (
            <div className="slider__header">
              <div className="slider__title-container">
                <span className="slider__blackslash">\</span>
                <h2 className="slider__title">&nbsp;Our Projects</h2>
              </div>
              <div className="slider__slide-number">
                /<br />0{(swiperInstance?.realIndex ?? 0) + 1}
              </div>
            </div>
          )}
          <div className="slider__slider">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={5}
              className="mySwiper"
              modules={[Mousewheel]}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              onSlideChange={(arg) => {
                setSlideCount(arg.realIndex);
                if (props.onSlideChange) {
                  props.onSlideChange(arg.realIndex);
                }
              }}
            >
              {slides.map((slide) => (
                <SwiperSlide
                  key={slide.id}
                  className={`slide ${
                    !isImageSlider ? 'swiper-slide__phrase' : 'swiper-slide__image'
                  }`}
                >
                  {isImageSlider && (
                    <>
                      <picture onClick={() => navigate(slide.link)}>
                        <source srcSet={slide.imgMobile} media="(min-width:320px)" />
                        <img
                          src={slide.imgMobile}
                          alt="img"
                          onClick={() => navigate(slide.link)}
                          loading="lazy"
                        />
                      </picture>
                      <div className="slide__description">
                        Client
                        <span className="slide__client" onClick={() => navigate(slide.link)}>
                          {slide.title}
                        </span>
                      </div>
                    </>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
